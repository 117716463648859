.homeTayTrangA {
    background: #f4f4f4 url(../../images/tay-trang/a-bg.jpg) center 0 no-repeat;
    text-align: center;
    min-height: 539px;

    @media #{$r1024} {
        background-size: 150%;
        min-height: 432px;
    }

    @media #{$r768} {
        padding-bottom: 20px;
        background-image: none;
        min-height: auto;
    }

    .w3-row {
        &>div {
            padding: 0 10px;
        }
    }

    &__item {
        font-weight: 600;
        font-size: 16px;
        @media #{$r414} {
            font-size: 14px;
        }
    }

    &__pic {
        margin-bottom: 10px;

        img {
            max-width: 100%;
            border-radius: 4px;
        }
    }

    &__tt {
        font-size: 13px;
        text-transform: uppercase;

        @media #{$r414} {
            font-size: 12px;
            line-height: 20px;
        }
    }
}
.homeTayTrangB {
    background: #08407d;
    text-align: center;

    @media #{$r768} {
        padding-bottom: 10px;
    }

    .w3-row {
        margin: 40px -10px 20px;

        @media #{$r1024} {
            margin: 20px 0 0px;
        }

        @media #{$r414} {
            margin: 20px -10px 0px;
        }

        &>div {
            padding: 0 15px;

            @media #{$r1024} {
                padding: 0 5px;
            }
        }
    }

    &__item {
        padding: 20px 40px;
        margin: 0 auto;
        background: #2a86df;
        color: #fff;
        height: 100%;
        border-radius: 4px;
        @media #{$r1024}{
            background: none;
        }
        @media #{$r768} {
            padding: 20px;
        }
        @media #{$r414} {
        }
    }

    &__pic {
        margin-bottom: 10px;
        padding: 6px;
        max-width: 70%;
        margin:0 auto 20px;
        img {
            width: 200px;
            height: 200px;
            border-radius: 100%;

            @media #{$r768} {
                width: 140px;
                height: 140px;
            }

            @media #{$r414} {
                width: 100px;
                height: 100px;
            }
        }
    }

    &__tt {
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 5px;
        @media #{$r1024}{
            font-size:13px;
        }
        @media #{$r414} {
            font-size: 12px;
            line-height: 17px;
        }
    }

    &__ct {
        font-size: 13px;
        line-height: 20px;

        @media #{$r414} {}

        ul {
            padding: 0 0 0 10px;
        }
    }
}
.homeTayTrangC {
    background: #e9e9e9;
    &__item {
        background: #fff;
        text-align: center;
        padding: 20px;
        margin-top: 20px;
        min-height: 300px;

        @media #{$r768} {
            min-height: 330px;
        }

        @media #{$r414} {
            min-height: 280px;
        }
    }

    &__pic {
        margin-bottom: 10px;
        padding: 6px;

        img {
            width: 180px;
            height: 180px;
            border-radius: 100%;
        }
    }

    &__tt {
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 5px;
        color: #e43c3b;

        @media #{$r414} {
            font-size: 12px;
            line-height: 17px;
        }
    }

    &__ct {
        font-size: 13px;
        line-height: 20px;
    }
}
.homeTayTrangD {
    background: #f4f4f4;
    &__item {
        background: #fff;
        text-align: center;
        padding: 15px;
        margin-top: 20px;
        min-height: 300px;

        @media #{$r768} {
            min-height: 330px;
        }

        @media #{$r414} {
            min-height: 280px;
        }
    }

    &__pic {
        margin-bottom: 10px;
        padding: 6px;

        img {
            width: 180px;
            height: 180px;
            border-radius: 100%;
        }
    }

    &__tt {
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 5px;
        color: #e43c3b;

        @media #{$r414} {
            font-size: 12px;
            line-height: 17px;
        }
    }

    &__ct {
        font-size: 13px;
        line-height: 20px;
    }
}