@import "var";
.homeAlbum{
    background:#08407d;
    .w3-row{
        margin:0px -10px;
        @media #{$r414}{
            margin: 0;
        }
        &>div{
            padding:0 15px;
        }
    }
    &__item{
        
        color:#0073ba;
    }
    &__pic{
        margin-bottom:10px;
        padding:6px;
        background:#fff;
        img{
            width:100%;
        }
        a{
            cursor: pointer;
        }
    }
    &__tt{
        text-transform: uppercase;
        margin-bottom:5px;
        color:#fff;
        @media #{$r414}{
            text-transform: none;
            text-align: center;
        }
    }
  
}
.homeTech{
	position: relative;
	z-index: 9;
	&__note{
        margin:0 50px;
        text-align: center;
		@media #{$r414}{
			margin:0 10px;
		}
	}
    article {
        position: relative;
        background-size: 100%;
        position: relative;
        margin-top:20px;
    }
    img{
        width:100%;
        border-radius: 4px;
        height: 168px;
        @media #{$r320}{
            height:128px;
        }
    }
    &__tt{
        background: #00abff;
        color: #fff;
        width: 100%;
        padding: 10px 25px;
        margin-bottom:10px;
        min-height:80px;
        @extend %font1;
        @media #{$r768} {
            font-size:10px;
        }
        @media #{$r414}{
            line-height:16px;
            min-height: auto;
            padding:15px;
        }
        &>div:nth-child(1){
            text-transform: uppercase;
            font-weight: 600;
            font-size:16px;
        }
        &>div:nth-child(2){
            font-size: 13px;
            line-height: 18px;
        }
    }
}
.homeNewEvent{
    padding:40px 0;
    @media #{$r414}{
        padding:20px 0;
    }
    .w3-row{
        margin:0 -10px;
        @media #{$r414}{
            margin:0;
        }
        &>div{
            padding:0 10px;
        }
    }
    &__left{
        .w3-row{
            margin:0 -10px;
            &>div{
                padding:0 10px;
            }
        }
    }
    ul{
        padding:15px 0 0;
        margin: 5px -10px 0;
        display: flex;
        flex-wrap: wrap;
        li{
            list-style: none;
            width:50%;
            padding:0 10px;
            img{
                width:100%;
            }
            p{
                margin: 5px 0;
                font-size:13px;
            }
            a{
                margin-bottom:10px;
                width:100%;
                line-height: 18px;
            }
        }
    }
    &__pic{
        &-big{
            width:100%;
            margin-bottom:10px;
        }
        &-middle{
            width:160px;
            padding-right:10px;
            @media #{$r414}{
                width:120px;
            }
        }
        &-small{
            width:70px;
            padding-right:10px;
        }
        img{
            width:100%;
        }
    }
    &__tt{
        color:#000;
        font-weight: 500;
        line-height: 20px;
        display: block;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        text-align: justify;
        @media #{$r768} {
            font-size:13px;
        }
        &-big{
            text-transform: uppercase;
            font-weight: 600;
            font-size:15px;
            margin-bottom:5px;
        }
        &-mid{
            -webkit-line-clamp: 4;
            @media #{$r414}{
                -webkit-line-clamp: 2;
            }
        }
        b{
            display: block;
            margin-bottom: 5px;
            text-align: left;
            @media #{$r414}{
                height:37px;
                overflow: hidden;
            }

        }
    }
    &__desc{
        text-align: justify;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        @media #{$r414}{
            -webkit-line-clamp: 5;
        }
        @media #{$r320}{
            -webkit-line-clamp: 3;
        }
    }
	&__event{
		margin:0 0 15px;
        border-bottom: 1px solid #d3d3d3;
        padding: 0 0 20px;
        &List{
            padding: 0 0 10px;
            display:table;
            &>div{
                display: table-cell;
                vertical-align: top;
            }        

        }
	}
}
.homeNewAsk{
    background:#f4f4f4 url(../../images/tay-trang/homeNewAsk-bg.jpg) center 0 no-repeat;
    padding:40px 0 0;
    @media #{$r768}{
        display: none;
    }
    @media #{$r414}{
        padding:20px 0 0;
    }
    .w3-row{
        margin:0 -10px;
        &>div{
            padding:0 10px;
        }
    }
    &__tinTuc{
        padding-right:20px;
    }
    ul{
        padding:15px 0 0;
        margin: 5px -10px 0;
        display: flex;
        flex-wrap: wrap;
        li{
            list-style: none;
            width:50%;
            padding:0 10px;
            img{
                width:100%;
            }
            p{
                margin: 5px 0;
                font-size:13px;
            }
            a{
                margin-bottom:10px;
                width:100%;
                line-height: 18px;
            }
        }
    }
    &__pic{
        &-big{
            width:100%;
            margin-bottom:10px;
        }
        &-middle{
            width:90px;
            padding-right:10px;
            @media #{$r414}{
                width:120px;
            }
        }
        &-small{
            width:90px;
            padding-right:10px;
            @media #{$r414}{
                width:120px;
            }
        }
        img{
            width:100%;
        }
    }
    &__tt{
        color:#000;
        font-weight: 500;
        line-height: 20px;
        display: block;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        text-align: justify;
        @media #{$r768} {
            font-size:13px;
        }
        &-big{
            text-transform: uppercase;
            font-weight: 600;
            font-size:15px;
            margin-bottom:5px;
        }
        &-mid{
            -webkit-line-clamp: 3;
        }
        b{
            display: -webkit-box;
            margin-bottom: 5px;
            color:#004689;
            text-align: left;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    &__desc{
        text-align: justify;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        @media #{$r414}{
            -webkit-line-clamp: 5;
        }
        @media #{$r320}{
            -webkit-line-clamp: 3;
        }
    }
	&__event{
		margin:0 0 15px;
        border-bottom: 1px solid #d3d3d3;
        padding: 0 0 20px;
        &List{
            padding: 0 0 20px;
            display:table;
            &>div{
                display: table-cell;
                vertical-align: top;
            }        

        }
	}
}
.homeBrand{
    padding:40px 0;
    @media #{$r414}{
        padding:20px 0;
    }
    &Title{
        line-height: 1.5;
        color:$main;
        text-transform:uppercase;
        padding:0;
        margin:0 0 2px;
        font-size:20px;
        text-align: center;
        font-weight: 700;
        @extend %font1;
        @media #{$r768} {
            font-size:18px;
        }
        @media #{$r414} {
            font-size:17px;            
        }
        &-white{
            color:#fff;
        }
        &-left{
            text-align:left;
            @media #{$r414}{
                text-align:center;
            }
        }
    }
 
    &Sub{
        line-height: 1.5;
        color:$main;
        text-transform:uppercase;
        padding:0;
        margin:0 0 2px;
        font-size:16px;
        text-align: center;
        font-weight: 600;
        @extend %font1;
        @media #{$r414}{
            font-size:13px;
            text-transform: none;
        }
        &-white{
            color:#fff;
        }
    }
    &__line{
        height: 1px;
        width: 102px;
        margin:15px auto 20px;
        border:none;
        border-bottom: 2px dashed #e43c3b;
        &-white{
            border-bottom: 2px dashed #fff;
        }
        &-left{
            margin:10px 0 20px;
            @media #{$r414}{
                margin:10px auto 20px;
            }
        }
    }
}