.newslist {
    padding:30px;
    @media #{$r414}{
        padding:30px 5px;
    }
    h2{
        margin:-20px 0 0;
        padding:8px 20px;
        background:#ededed;
        color:#000;
        font-style:italic;
        font-size:14px;
        @media #{$r414}{
            margin:0;
        }
    }
    article {
        display: table;
        padding:20px 0;
        border-bottom:1px dotted #ddd;
        @media #{$r414}{
            margin:0 10px;
        }
        @media #{$r375}{
            padding:10px 0;
        }
        &:first-child{
            padding-top:0;
        }
        div:nth-child(1) {
            float: left;
            margin-right: 20px;
            @media #{$r414}{
                width:160px;
            }
            @media #{$r375}{
                width:120px;
            }
            @media #{$r320}{
                width:100px;
            }
            img{
                width:220px;
                height:150px;
                @media #{$r414}{
                    height:auto;
                    width:100%;
                }
            }
        }
        div:nth-child(2) {
            @media #{$r414}{
                width:auto;
                float:none;
            }
            .title{
                @extend %font1;
                @media #{$r414}{
                    margin: 0;
                    line-height: 16px;
                }
                a{
                    color:$main;
                    font-size:16px;
                    font-weight: 500;
                    @media #{$r320}{
                        font-size:14px;
                    }
                }
            }
            p{
                text-align: justify;
                -webkit-line-clamp:3;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                display:-webkit-box;
                overflow: hidden;
                height:auto !important;
                @media #{$r414}{
                    text-align: left;
                    line-height: 18px;
                    font-size:13px;
                }
            }
            .more{
                a{
                    color:#666;
                    text-decoration:underline;
                    font-style:italic;
                    font-size:13px;
                }
            }
        }
    }
}