$color1:#047bc1;
$color2:#e13c39;
$main:#00619b;
$gray:#4c4c4c;
$paris:#006199;
%tab{
    display:table;
    width:100%;
    > div{
        vertical-align:top;
        display:table-cell;
    }
}
%noline{
    &:hover{
        text-decoration: none;
    }
}
.effect{transition:all 500ms ease-in-out 0s;}
%font1{
  font-family:-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
}
.w3-row{
    display: flex;
    flex-wrap: wrap;
    width:100%;
    &:before,&:after{
        display: none;
    }
}
.w3-col{
    float:none;
}
%hd-shadow{
	box-shadow:0 4px 8px #ddd;
}
$d1366: "(max-width: 1366px)";
$t1024: "(max-width: 1024px)";
$t768: "(max-width: 812px)";
$m480: "(max-width: 480px)";
$m414: "(max-width:414px)";
$m375: "(max-width:375px)";
$m360: "(max-width:360px)";
$m320: "(max-width:320px)";
$r1366: "(max-width:1366px)";
$r1024: "(max-width:1024px)";
$r768: "(max-width:812px)";
$r414: "(max-width:414px)";
$r375: "(max-width:375px)";
$r360: "(max-width:360px)";
$r320: "(max-width:320px)";
body{
  font-size:14px;
  color:#414141;
  line-height: 25px;
  font-family: arial;
}
a{text-decoration: none;}
iframe{border:none;}
.aligncenter {
    margin: 5px auto;
    display: block;
    max-width: 100%;
}
.responsive img{width:100%}
.responsive iframe{width:100%}
.pc{
    display: block;
    @media #{$m480} {
        display: none;
    }
}
.mb{
    display: none;
    @media #{$m480} {
        display: block;
    }
}
%icon{
	width:20px;
	height: 20px;
	font-family: "fontello";
	font-style: normal;
	font-weight: 400;
	// speak: none;
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	margin-left: .2em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}
.regft{
    display: none;
    @extend %font1;
    @media #{$r414} {
        display:flex;
        display: -webkit-flex;
        width:100%;
        height:60px;
        position: fixed;
        background:rgba(0,0,0,0.5);
        left:0;
        bottom:0;
        z-index: 9;
        a{
            -webkit-flex: 1;
            -ms-flex: 1;
            flex: 1;
            margin:10px 0 10px 10px;
            height:40px;
            line-height: 40px;
            color:#fff;
            text-align: left;
            border-radius: 4px;
            font-size:15px;
            font-weight: normal;
            text-transform: uppercase;
            padding-left:20px;
            &:hover,&:focus{
                text-decoration: none;
            }
            &:nth-child(1){
                background:$color1;
                box-shadow:0px 3px 0px 0px #10384a
            }
            &:nth-child(2){
                background:#e23d3a;
                margin-right: 10px;
                box-shadow: 0px 2px 0px 0px #8f2725;
            }
        }
    }
}