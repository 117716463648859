$r1366: "(max-width:1366px)";
$r1024: "(max-width:1024px)";
$r768: "(max-width:812px)";
$r414: "(max-width:414px)";
$r375: "(max-width:375px)";
$r360: "(max-width:360px)";
$r320: "(max-width:320px)";
$main:#00619b;
%font1 {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}