
.page_container,.container{
	max-width: 1140px;
	margin:0 auto;
	@media #{$t1024} {
		margin:0 10px;
	}
	@media #{$m480} {
		margin:0 10px;
	}
}
.breadcrumb{
	background: #ededed;
	padding:0 25px;
	margin:0px 0 5px 0px;
	border-bottom: 1px solid #ddd;
	-webkit-line-clamp:1;
	-webkit-box-orient:vertical;
	overflow:hidden;
	text-overflow: ellipsis;
	display:-webkit-box;
	height: 40px;
	p{
		margin:0;
		padding:0;
	}
	a{
		color:#000;
		padding:6px 13px 7px 0;
		display:inline-block;
		font-size:12px;
		font-weight: bold;
		text-decoration:underline;
		color:$main;
		position: relative;
		&::before{
			@extend %icon;
			position: absolute;
			right:-4px;
			top:13px;
			content:"\e895";
			font-size:10px;
			color:#333;
			text-decoration: none;
		}
		&:hover{
			color:#222;
		}
	}
	.breadcrumb_last{
		
	}
	@media #{$r1024} {
		margin:0 0 10px 0;
		padding:0 0 0 20px;
	}
}

.regist{
	position: relative;
	width:100%;
	button,a{
		background:url(../../images/regist.png) 0 0 no-repeat;
		width:230px;
		height:55px;
		border:none;
		display: block;
		margin:0 auto;
		@media #{$t768} {
			background-size:100%;
			width:160px;
		}
	}
}

.button {
	margin: 30px 0;
	a {
		background: #e13c39;
		border-radius: 5px;
		padding: 10px 20px;
		display: block;
		text-align: center;
		color: #fff;
		font-size: 14px;
		display: table;
		@extend %font1;
	}
}

.main_tt{
	font-size: 20px;
	margin: 0;
	text-transform: uppercase;
	padding:30px 30px 0;
	font-weight: 600;
	@extend %font1;
	color:$main;
	@media #{$r414} {
		margin:10px;
		background:none;
		color:#047bc1;
		padding:0 4px;
		font-size:16px;
	}
}
.sapo{
	background: #eef9ff;
	color: #047bc1;
	padding: 20px;
	font-size: 16px;
	text-align: justify;
	border: 1px dashed #047BC1;
	margin: 20px 30px 0;
	@media #{$r414}{
		margin:20px 15px 0;
	}
}
.cmName{
	margin:2px 0 0 32px;
	font-size:12px;
}
.update{
	display: none;
	font-size:11px;
	color:#111111;
	padding:0 0 0 16px;
	margin:2px 0 0 32px;
	position: relative;
	&::before{
		@extend %icon;
		position: absolute;
		left:-1px;
		top:6px;
		content: "\e87b";
		
	}
}
.w3-pagination{
	margin:40px 0;
	a,.pages,span{
		background:#d0d0d0;
		color:#333;
		margin:0 3px;
		padding:8px 16px;
		@extend %font1;
		font-size:15px;
		&.current,&:hover{
			background:#3695cd;
			color:#fff;
		}
	}
	@media #{$m320} {
		margin:20px 0;
		a,.pages,span{
			padding:8px 13px;
			margin:0 1px;
			font-size:15px;
		}
	}
}

.call-reg {
	width: 150px;
	float: left;
	@media #{$t768} {
		display: none;
	}
	.regist_top,.call_top{
		position: static;
		display: block;
		padding: 14px 25px 14px 40px;
		position: relative;
		font-size:14px;
		&::before{
			@extend %icon;
			position: absolute;
			font-size:20px;
		}
	}
}
.ftadbox{
	display:none;
	@media #{$m480} {
		display:block;
	}
}
.tag{
	padding:0 20px;
	font-size:11px;
	span{
		background:#deedf5;
		padding:3px 8px;
		border-radius: 4px;
		margin:4px;
	}
	a{
		color:#006099;
	}
}
.dangxem{
	padding:0 30px;
	font-size:11px;
	a{
		color:$main;
		font-weight:bold;
	}
}
#text-12{
	padding-bottom: 12px;
	list-style: none;
}
.slidehome{
	max-width: 1366px;
	margin:10px auto;
	ul{
		padding:0;
	}
	.flex-control-nav{
		text-align: right;
		bottom:0;
		right:10px;
	}
	.flex-control-paging li a{
		text-indent: 9999px;
		background:#cfcfcf;
		box-shadow: none;
	}
	.flex-control-paging li a.flex-active{
		background:#777;
	}
	.flex-direction-nav a{
		opacity: 1;
		width:34px;
		height:64px;
		display: block;
		top:46%;
		font-size:30px;
		color:#fff;
		opacity: 0.8;
	}
	.flex-direction-nav .flex-next{
		right:20px;
	}
	.flex-direction-nav .flex-prev{
		left:20px;
	}
	@media #{$t1024} {
		margin:0;
		.flex-control-nav{
			text-align:center;
			bottom:-40px;
		}
		.flex-control-paging li a{
			width:8px;
			height:8px;
		}
	}
}

.quick_link{
	padding:20px 0;
	margin:0;
	background:#ececec;
	ul{
		padding:0 0 0 20px;
		width:20%;
		float:left;
		li{
			a{
				text-decoration:none;
				color:#4c4c4c;
				font-size:14px;
				&:hover{
					color:#006cac;
				}
			}
		}
	}
	@media #{$m480} {
		margin:2px 0 0;
		ul{
			display: none;
			width:50%;
		}
		ul:nth-child(1),ul:nth-child(2){
			display: block;
		}
	}
}
.boxmain{
	width:100%;
	display: flex;
	margin:20px 0 50px;
	@media #{$m414} {
		width:96%;
		margin:0 auto;
	}
	
}
.sidebar{
	width:25%;
	padding-left: 15px;
	@media #{$t768} {
		display:none;
	}
	.adv img{
		width:100%;
	}
	.slidesb{
		li{
			text-align: center;
		}
		.flex-control-nav{
			bottom:-25px;
		}
		.flex-control-paging li a{
			text-indent: 9999px;
			background:#dddddd;
			box-shadow: none;
		}
		.flex-control-paging li a.flex-active{
			background:#888888;
		}
	}
	.title{
		text-transform:capitalize;
		margin:0;
		a{
			@extend %noline;
			text-transform: uppercase;
			font-weight: 600;
			color:#fff;
			background:#4594df;
			padding:8px 15px;
			display:block;
			@extend %font1;
		}
	}
	.tuvan {
		margin: 10px 0 0;
	}
	.sb-tuvan {
		background: url(../../images/tuvan.jpg) no-repeat center 0;
		background-size: 100%;
		width: 100%;
		height: 210px;
		position: relative;
		a {
			background: linear-gradient(#ff6c62,#e70415);
			padding: 5px 25px;
			text-transform: uppercase;
			color: #fff;
			text-align: center;
			position: absolute;
			bottom: 10px;
			left: 40px;
			border-radius: 30px;
			border: 2px solid #ffc2be ;
			box-shadow: 0px 0px 1px  #e60012;
		}
	}
	.bct{
		margin:0 0 15px;
		border:1px solid #ddd;
		padding:15px;
		iframe{
			width:100%;
			height:170px;
		}
	}
	.sbnewb{
		color:$main;
		font-weight: bold;
		line-height: 20px;
		text-transform: uppercase;
		font-size:13px;
		img{
			width: 100%
		}
		p{
			margin:8px 0 10px;
		}
	}
	.sbnew{
		img{
			float:left;
			margin:0 10px 0 0;
			width:48px;
			height:48px;
		}
		a{
			font-size:13px;
			color:$main;
			display: table;
			margin:0 0 15px;
			line-height:20px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	ul.icon{
		background: #1268b3;
		padding:10px 20px;
		margin:0 0 10px;
		@extend %font1;
		li{
			list-style: none;
			padding:13px 0 13px 36px;
			border-bottom: 1px solid #558cc1;
			position: relative;
			font-weight: 500;
			&:last-child{
				border-bottom: none;
			}				
			&::before{
				background-image: url(../../images/sidebar/sb-icon.png);
				width:30px;
				height: 40px;
				position: absolute;
				left:0;
				top:4px;
				content: "";
			}
			a{
				color:#fff;
			}
			&:nth-child(1)::before{
				background-position: center 0;
			}
			&:nth-child(2)::before{
				background-position: center -42px;
			}
			&:nth-child(3)::before{
				background-position: center -86px;
			}
			&:nth-child(4)::before{
				background-position: center -130px;
			}
			&:nth-child(5)::before{
				background-position: center -170px;
			}
		}
	}
	ul.list{
		margin:0 0 15px 0;
		padding:0;
		border:1px solid #ddd;
		border-bottom: none;
		li{
			position: relative;
			padding:7px 0 7px 32px;
			list-style: none;
			border-bottom: 1px solid #ddd;
			&::before{
				@extend %icon;
				content:"\e895";
				left:9px;
				top:12px;
				position: absolute;
				font-size:12px;
			}
			a{
				@extend %noline;
				color:#333;
				font-size:14px;
				margin-left:-3px;
				&:hover{
					color:#c00;
				}
			}
		}
	}
	.menu_left{
		.panel{
			border:none;
			box-shadow:none;
			margin-bottom:10px;
			h4{
				font-size:13px;
				text-transform:uppercase;
				position: relative;
				padding:4px 2px 8px 10px;
				margin:0;
				i{
					position: absolute;;
					left:0;
					top:6px;
				}
				a,.fa-caret-right{
					color:$paris;
					@extend %noline;
				}
				a:focus,.fa-caret-down{
					color:#c00;
					outline:none;
				}
			}
			ul li a{
				color:$gray;
				&:hover{
					color:#c00;
				}
			}
		}
	}
}
.main{
	padding-bottom:20px;
	border:1px solid #ddd;
	width:75%;
	.advtop{
		text-align: center;
		@media #{$r414}{
			padding:0 10px;
		}
		img{
			max-width: 100%;
			border-bottom:1px solid #ddd;
		}
	}
	
	
	
	@media #{$t1024} {
		width:72%;
	}
	@media #{$t768} {
		width:100%;
	}
	@media #{$m414} {
		padding:0;
	}
}


