.homeSlideIntro{
    position: relative;
    color:$color1;
    z-index: 0;
    .owl{
        &-dots{
            position: absolute;
            width:100%;
            bottom:0;
        }
        &-nav{
            margin-top:0;
            [class*=owl-]{
                margin:0;
                border-radius: 0;
                &:hover{
                    background:rgba(255,255,255,.2);
                }
            }
            button{
                position: absolute;
                height:100%;
                width:50px;
                top:0;
                margin:0;
                
                
            }
            font-size:34px;
        }
        &-prev{
            left:0;
        }
        &-next{
            right:0;
        }
        
    }
}