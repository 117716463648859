.content{
    padding:30px 30px 10px;
    text-align: justify;
    @media #{$r414}{
        padding:10px 15px;
    }
    h2{
        background:#eef9ff;
        color:$color1;
        padding:20px;
        font-size:16px;
        text-align: justify;
        border:1px dashed #047BC1;
        margin:0;
    }
    .cttt{
        text-transform:uppercase;
        color:$color1;
        text-align: center;
        font-size:18px;
        margin:30px 0 10px;
    }
    .tinkhac{
        margin: 20px 0 0;
        padding-top:20px;
        border-top:1px solid #ddd;
        .link_box{
            padding:0 12px;
            margin:0;
            padding-left: 0;
            li{
                color:$main;
                list-style: none;
                margin:10px 0;
                a:hover{
                    color:$color2;
                }
            }
        }
        .tktt{
            padding:0;
            color:#888;
            margin-bottom:15px;
            text-transform: uppercase;
            @extend %font1;
            font-size:18px;
        }
        .w3-row{
            a{
                width:190px;
                display: block;
                line-height: 20px;
                img{
                    width:98%;
                    height:130px;
                    margin-bottom: 2px;
                }
                p{
                    margin:0;
                    padding:5px 0 0;
                    text-align: center;
                }
                &:hover{
                    color:#047bc1;
                }

            }
        }
        @media #{$t1024} {
            .w3-row{
                margin-right:-16px;
                a{
                    width:90%;
                }
                a:nth-child(3){
                    clear: both;
                }
            }
        }
        @media #{$m414} {
            .tktt{
                margin-bottom:20px;
            }
            .w3-row{
                a{
                    margin-bottom: 20px;
                }
            }
        }
        @media #{$m320} {
            .w3-row a img{
                height:105px;
            }
        }
    }
}
.advdk{
    text-align: center;
    margin:10px 30px 25px;
    border-bottom:1px dotted #ddd;
    padding-bottom: 20px;
    img{
        max-width:100%;
    }
    .tag {
        text-align: left;
        font-size: 12px;
    }
    .dangxem {
        font-size: 11px;
        text-align: left;
    }
}
.tinkhac{
    margin: 20px 30px 0;
    padding-top:20px;
    border-top:1px solid #ddd;
    .link_box{
        padding:0 12px;
        margin:0;
        li{
            color:$main;
            margin:5px 0;
            a:hover{
                color:$color2;
            }
        }
    }
    .tktt{
        padding:0;
        color:#888;
        margin-bottom:15px;
        text-transform: uppercase;
        @extend %font1;
        font-size:18px;
    }
    .w3-row{
        a{
            width:190px;
            display: block;
            line-height: 20px;
            img{
                width:98%;
                height:130px;
                margin-bottom: 2px;
            }
            p{
                margin:0;
                padding:5px 0 0;
                text-align: center;
            }
            &:hover{
                color:#047bc1;
            }

        }
    }
    @media #{$t1024} {
        .w3-row{
            margin-right:-16px;
            a{
                width:90%;
            }
            a:nth-child(3){
                clear: both;
            }
        }
    }
    @media #{$m414} {
        .tktt{
            margin-bottom:20px;
        }
        .w3-row{
            a{
                margin-bottom: 20px;
            }
        }
    }
    @media #{$m320} {
        .w3-row a img{
            height:105px;
        }
    }
}
.othernew{
    margin:15px 30px 0px;
    padding-top:20px;
    border-top:1px dotted #ddd;
    width:auto;
    @media #{$r414}{
        margin:15px 15px 0;
    }
    .w3-col:nth-child(1){
        padding-right:15px;
    }
    .w3-col:nth-child(2){
        padding-left:15px;
    }
    article{
        .other_tt{
            color:#888888;
            text-transform:uppercase;
            font-size:18px;
            @extend %font1;
        }
        ul{
            padding:0;
            li{
                list-style: none;
                padding:0 0 0px 12px;
                margin:5px 0;
                font-family: arial;
                position: relative;
                &::before{
                    @extend %icon;
                    position: absolute;
                    top:6px;
                    left:-2px;
                    content:"\e895";
                    font-size:10px;
                }
                
                a{
                    color:$main;
                    &:hover{
                        color:#c00;
                    }
                }

            }
        }
    }
    @media #{$m414} {
        .w3-col:nth-child(1),.w3-col:nth-child(2){
            padding:0;
        }
    }
}