#ftmng {
	background: #006CAC;
	line-height: 25px;
	padding-bottom: 40px;
	@media #{$r414} {
		line-height: 21px;
	}
	@extend %font1;
	.icon_ft{
		background-image: url(../../images/footer_NK.png);
	}
	&>div {
		padding: 0;
		margin: 0;
	}
	&>p {
		padding: 0;
		margin: 0;
	}
	a {
		line-height: 20px;
	}

	.container {
		max-width: 1366px;
		margin: 0 auto;
		position: relative;
		padding: 0;
	}
	.row1 {
		.row {
			padding: 25px 10px;
			min-height: 125px;
			.footer-logo{
				width: 50%;
				float: left;
				margin: 0 auto;
				padding-top: 0;
				i{
					width:170px;
					height: 62px;
					display: block;
					background-position: center -13px;
				}
			}
			.footer-social {
				width: 50%;
				float: left;
				.ft-hotline {
					position: absolute;
					right: 20px;
					background: #e13c39;
					border: none;
					border-radius: 4px;
					padding: 5px 0;
					text-align: center;
					font-weight: bold;
					i {
						vertical-align: top;
						margin-right: 5px;
						display:inline-block;
						width:20px;
						height: 20px;
						background-position: 45px 105px;
						
					}
					a {
						color: #fff;
						text-decoration: none;
						padding: 7px 30px;
					}
				}
				.social-img {
					position: absolute;
					right: 40px;
					top: 60px;
					padding-top: 10px;
					i{
						width:34px;
						height: 34px;
						display: inline-block;
						
					}
					a:nth-child(1) i{
						background-position: 202px 111px;
					}a:nth-child(2) i{
						background-position: 152px 111px;
					}a:nth-child(3) i{
						background-position: 102px 111px;
					}
				}
			}
		}
	}
	.row2 {
		background: #006099;
		.r2-row {
			display: table;
			padding: 20px 10px;
			width:100%;
			.footer-r2-col1,.footer-r2-col2 {
				color: #fff;
				font-size: 13px;
				width: 50%;
				float: left;
				.hcm,.hn {
					clear: both;
				}
				.dn {
					padding-top: 10px;
				}
				.hn {
					height: 80px;
				}
				.location {
				    float: left;
				    width: 15%;
				    p {
				    	background: #87c5ea none repeat scroll 0 0;
						border-radius: 8px;
						color: #006099;
						float: left;
						font-weight: bold;
						height: auto;
						margin: 5px 0;
						padding: 9px 0;
						text-align: center;
						text-transform: uppercase;
						width: 84px;
				    }
				}
				.gg-maps {
					padding-left: 21px;
					position: relative;
					&::before{
						width:18px;
						height: 18px;
						content:"";
						@extend .icon_ft;
						background-position:-16px -575px;
						position: absolute;
						left:0;
						top:2px;
						display: block;
					}
					a {
						text-decoration: underline;
					}
				}
				.tel {
					padding-left: 10px;
					width: 33%;
					float: left;
					p {
						margin: 0;
					}
					a {
						color: #fff;
						text-decoration: none;
					}
					@media #{$r1024} {
						p {
							padding: 0 10px;
						}
						.gg-maps{
							padding-left: 21px;
						}
					}
				}
				.tel-mb {
					display: none;
					@media #{$r768} {
						.tel-mb {
							display: block;
							padding: 10px 0;
							float: left;

						}
					}
				}
				.add {
					float: left;
					width: 50%;
					ul {
						padding-left: 5px;
						margin: 0;
						li {
							list-style: none;
							padding-left: 15px;
							background-size: 7.5px;
							position: relative;
							&::before{
								@extend .icon_ft;
								position: absolute;
								content: "";
								left:0;
								top:4px;
								width:20px;
								height: 20px;
								background-position:-69px -155px;
								@media #{$r414} {
									top:0;
								}
								
							}
							
							a {
								color: #fff;
								text-decoration: none;
								&:hover {
									color: #87CBEB;
									text-decoration: none;
								}
							}
						}
					}
				}
			}
		}
	}
	@media #{$r1024} {
		.row2 {
			.r2-row {
				.footer-r2-col1,.footer-r2-col2 {
					.hn {
						height: 100px;
						@media #{$r414} {
							height: 68px;
							margin-top: 20px;
						}
					}
				}	
			}
		}
	}
	@media #{$r768} {
		.tel {
			display: none;
		}
		.row2 {
			.r2-row {
				.footer-r2-col1,.footer-r2-col2 {
					.location {
						width: 100%;
					}
					.add {width: 100%; padding: 5px 0;}
					.tel-mb {
						display: block;
						padding: 10px 0;
						@media #{$r414} {
							padding:0;
						}
						float: left;
						p {
							margin: 0;
							@media #{$r375} {
								margin: 0 10px;
							}
						}
						a {
							color: #fff;
							text-decoration: none;
						}
						
					}
				}
			}
		}
	}
	@media #{$r414} {
		.container {
			max-width: 100%;
			padding: 0;
		}
		.row1 {
			padding: 0px 10px;
			.row {
				min-height: auto;
				.footer-logo {
					width: 50%;
					float: none;
					img {
						width: 100%;
					}
				}
			}
		}
		.footer-social {
			display: none;
		}
		.row2 {
			.r2-row {
				.footer-r2-col1,.footer-r2-col2 {
					width: 100%;
					.location {
						width: 25%;
					}
					.tel-mb {
						width: 75%;
						
					}
					.add {
						width: 75%;
						float: right;
						@media #{$r375} {
							width:74%;
							padding:0;
						}
					}
				}
			}
		}
	}
	@media #{$r375} {
		.row2 {
			.r2-row {
				.footer-r2-col1,.footer-r2-col2  {
					.location {
						font-size: 13px;
					}
				}
			}
		}
	}
	@media #{$r320} {
		.row2 {
			.r2-row {
				.footer-r2-col1,.footer-r2-col2  {
					.location {
						font-size: 12px;
						p {
							width: 75px;
						}
					}
					.tel-mb,.add{
						font-size: 11px;
					}
				}
			}
		}
	}
}