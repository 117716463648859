#menu{
	z-index:70;
	padding:0;
	margin:0 0 0 210px;
	@media #{$r1024}{
		margin: 0 0 0 160px;
	}
	@media #{$r768}{
        display: none;
		width:250px;
		overflow:auto;
		&.menu_act{
			display: block;
			position: fixed;
			left:0;
			top:0;
			margin:0;
            background:#2b6090;
			height:100%;
			animation:animateleft 0.4s;
        }
        .logosb{
            background:url(../../images/logo.png) 0 0 no-repeat;
            width:161px;
            height:75px;
            display: block;
            margin:20px;
        }

	}
	.menu-icon{
		display: none;
		@media #{$r768}{
			display: block;
			position: absolute;
			right:10px;
			top:10px;
			color:#fff;
		}
	}
	i{display:block;}
	li {
		float:left;
		list-style: none;
        width: 130px;
        @media #{$r1024}{
            width:115px;
        }
		@media #{$r768}{
			float:none;
			width:100%;
		}
		&:hover a{
			background:rgba(0,0,0,0.1);
		}
		.w3-dropdown-content{
            @media #{$r768}{
                position:relative;
                display: none;
            }
			a{
				text-align: left;
				background:#105da1;
				padding:10px 10px 10px 30px;
				min-width:260px;
				font-family: arial;
                position: relative;
                @media #{$r768}{
					width:100%;
					min-width:100%;
                    background:#2b6090;
					color:#9dd2eb;
					font-size:12px;
                }
				&::before{
					@extend %icon;
					position: absolute;
					left:10px;
					top:15px;
					content:"\e895";
					font-size:11px;
				}
				&:hover{
                    background:#0a579c;
                    @media #{$r768}{
                        background:#2b6090;
                    }
				}
			}
		}
		a{
			text-decoration:none;
			padding:1px 20px;
			text-transform:capitalize;
			font-weight:400;
			font-size:13px;
			display:block;
			text-align: center;
			color:#fff;
			@media #{$r1024} {
                padding:0px 15px 0 0;
			}
			@media #{$r768}{
                padding:10px 20px; 
                font-size: 14px;
                text-align:left;
                border-top:1px solid #045070;
			}
			&:hover{background:rgba(0,0,0,0.1)}
			&.active{
				display:block;
				outline: none;
				background:rgba(255,255,255,0.1);
				border-bottom: 1px solid #d63736;
				color:#d63736;
			}
			span{
				padding:10px 0 8px;
				display:block;
				line-height: 18px;
				@media #{$r768}{
                    padding:0;
                    br{
                        display: none;
                    }
				}
			}
			img {
				margin: 0 auto;
				max-width: 100%;
				display: block;
			}
			
		}
	}
	.l1,.l2,.l3,.l4,.l5,.l6 {
		border-left: 1px solid #0153a2;
		span::before{
			width:40px;
			height: 40px;
			margin: 0 auto 8px;
			content: "";
			display: block;
            background-image: url(../../images/home/menu-icon.png);
            @media #{$r768}{
                display:none;
            }
		}
	}
	.l1 span::before {
		background-position: center 0;
	}.l2 span::before {
		background-position: center -50px;
	}.l3 span::before {
		background-position: center -92px;
	}.l4 span::before {
		background-position: center -130px;
	}.l5 span::before {
		background-position: center -170px;
	}.l6 span::before {
		background-position: center -206px;
	}
}
.menu-bg{
    background:rgba(0,0,0,.6);
    width:100%;
    height:100%;
    content:'';
    display: none;
    position: fixed;
    top:0;
    z-index: 10;
    &.act{
        display: block;
    }
}
.first {
	background: #0153a2;
	color: #fff;
	line-height: 2;
	clear: both;
	min-height: 30px;
	.address {
		width: 90%;
		float: left;
		font-size: 13px;
		a {
			font-size: 12px;
			vertical-align: middle;
			text-transform: uppercase;
			padding-left: 10px;
			display: inline-block;
			width: 100px;
			text-align: center;
			i{
				color:#FF5251;
			}
		}
	}
	.mxh {
		width: 10%;
		float: left;
		text-align: right;
		@media #{$r1024} {
			width:95px;
		}
		a {
			display: inline-block;
			width: 25px;
			img {
				margin:6px 0 0;
				border:1px solid #fff;
			}
		}
	}
	@media #{$t768} {
		display: none;
	}
}
header.back{
	background:#1268b3;
	display:table;
	padding:0px;
	width:100%;
	box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;
	font-size:13px;
	color:#000;
	-webkit-transition:all 500ms ease-in-out;-moz-transition:all 500ms ease-in-out;
	-o-transition:all 500ms ease-in-out;transition:all 500ms ease-in-out;
	box-shadow: 0 0 4px rgba(0,0,0,0.1);
	.w3-dropdown-content{
		z-index: 2;
	}
	
	&>div{
		position:relative
	}
	&.menu2{
		background:rgba(11,100,79,0.9);
	}
	.logo{
		background:url(../../images/logo.png) 0 0 no-repeat;
		background-size: 100%;
		position: absolute;
		left:0;
		top:17px;
		width:161px;
		height:75px;
		@media #{$r1024}{
            height: 60px;
			top:12px;
            margin:0 auto;
            display: block;
            background-position: center 0;
            width:128px;
        }
        @media #{$r768}{
            width:180px;
            background-size:auto;
            position: relative;

        }
        @media #{$r414}{
            height:41px;
        }
	}
	.menu_icon{
		display:none;
		position: absolute;
		top:18px;
		left:10px;
		color:#fff;
		font-size:40px;
	}
	.call_icon{
		background:$color2;
		width:40px;
		height:40px;
		display: block;
		position: absolute;
		top:18px;
		right:20px;
		display: none;
		border-radius: 6px;
		&::before{
			@extend %icon;
			position: absolute;
			color:#fff;
			font-size:23px;
			content:"\e874";
			top:7px;
			left:2px;
		}
	}
	.hotline{width:104px;transition:all 500ms ease-in-out 0s;position:absolute;top:0;right:190px;line-height:68px;color:#b72a27;font-size:16px;font-weight:400}
	.call_top{
		background:#e13c39;
		font-weight: normal;
		border-radius:5px;
		text-align: left;
		border-radius: 0;
		font-weight: 600;
		color:#fff;
		&::before{
			left:9px;
			top:18px;
			content:"\e874";
		}
	}
	.regist_top{
		@extend .call_top;
		border-radius: 0;
		background: #ff5251;
		&::before{
			left:9px;
			top:18px;
			content:"\e857";
		}
    }
    &.menufix{
		position: fixed;
		top:0;
		z-index: 11;
		#menu{
			margin: 0 0 0 160px;
			@media #{$r768}{
				margin:0;
			}
			li{
				width:108px;
				@media #{$r1024} {
					width:100px;
				}
				@media #{$r768}{
					width:100%;
				}
				span::before{
					display: none;
				}
				a{
					padding: 6px 10px;
					@media #{$r1024} {
						padding:6px 11px;
                    }
                    @media #{$r768}{
                        padding:10px 20px;
                    }
				}
				.w3-dropdown-content{
					a{
						padding: 10px 10px 10px 30px;
					}
				}
			}
		} 
		.call-reg{
			width:320px;
			@media #{$r1024} {
				width:244px;
			}
			.call_top, 
			.regist_top{
				width:45%;
				float: left;
				border-radius: 4px;
				margin:10px 0 0 10px;
				padding: 10px 10px 10px 40px;
				&:before{
					top:14px;
				}
				@media #{$r1024} {
					font-size:13px;
				}
			}
		}
		.logo{
			width:121px;
			height: 55px;
			top:5px;
			@media #{$r768} {
				width:100%;
				top:12px;
				height: 60px;
            }
            @media #{$r414}{
                height:40px;
            }
		}
	}
	
	@media #{$t768} {
		padding:0;
		min-height:80px;
		display: block;
		&>div{
			margin:0;
		}
		.hotline,.dangky-top{display:none}
		.menu_icon,.call_icon{
			display:block;
		}
		
	}
	@media #{$m480} {
		min-height: 60px;
		.logo{
			background-size:110px;
		}
		.menu_icon{
			font-size:31px;
			top:15px;
		}
		.call_icon{
			width:35px;
			height:35px;
			top:13px;
			right:15px;
		}
	}
}